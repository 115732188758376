import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import Category from './Category';
import Ratings from './Ratings';

import './recipe-card.css';

const RecipeCard = ({ id, image = false, slug, basicinfo, categories, title, ratings, cuisine, recipetype, author = false }) => {
    const { preptime, cooktime, cleantime } = basicinfo;
    const pageimage = (image) ? image._rawAsset.url : false;

    return (
        <article key={id} className="recipe-card" itemScope itemType="https://schema.org/Recipe">
            {pageimage && <div className="recipe-card__image" style={{ backgroundImage: `url(${pageimage})` }}></div>}

            {pageimage && <img src={pageimage} alt="" hidden itemProp="image" />}

            <h2 className="recipe-card__title">
                <Link
                    to={`/recipe/${slug.current}`}
                    className="recipe-card__link"
                >
                    <span itemProp="name">{title}</span>
                </Link>
                <meta itemProp="recipeCategory" content={recipetype} />
                <meta itemProp="recipeCuisine" content={cuisine} />
                {author && <span itemProp="author" hidden>{author}</span>}
            </h2>

            <div className="recipe-card__time">
                <meta itemProp="prepTime" content={`PT${preptime}M`} />
                <meta itemProp="cookTime" content={`PT${cooktime}M`} />
                <meta itemProp="totalTime" content={`PT${(preptime + cooktime + cleantime)}M`} />
                {preptime + cooktime + cleantime} min<span className="u-vh">ute</span>s
            </div>

            <ul className="u-category__list recipe-card__categories">
                {categories.map((category, index) => (
                    <Category
                        slug={category.slug.current}
                        title={category.title}
                        id={category.id}
                        key={`${category.id}-${index}`}
                    />
                ))}
            </ul>

            <Ratings
                ratings={ratings}
                styleName="stars"
                output="overall"
            />
        </article>
    );
};

export default RecipeCard;

RecipeCard.propTypes = {
    id: PropTypes.string,
    image: PropTypes.oneOfType([
        PropTypes.boolean,
        PropTypes.object,
    ]),
    slug: PropTypes.object,
    basicinfo: PropTypes.object,
    categories: PropTypes.object,
    title: PropTypes.string,
    ratings: PropTypes.object,
    cuisine: PropTypes.object,
    recipetype: PropTypes.string,
    author: PropTypes.oneOfType([
        PropTypes.boolean,
        PropTypes.string,
    ]),
};
