import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

const Category = ({ slug, title, isLink = false }) => {
    let suitableForDiet = false;

    switch(slug) {
        case 'gluten-free': {
            suitableForDiet = 'GlutenFreeDiet';
            break;
        }

        case 'vegan': {
            suitableForDiet = 'VeganDiet';
            break;
        }

        case 'keto': {
            suitableForDiet = 'LowCalorieDiet';
            break;
        }

        default: {
            suitableForDiet = false;
            break;
        }
    }

    return (
        <li
            className="u-category"
        >
            {suitableForDiet && <link itemProp="suitableForDiet" href={`https://schema.org/${suitableForDiet}`} />}

            {isLink &&
                <Link to={`/categories/${slug}`} className={`u-category__link u-category__link--${slug}`}>
                    {title}
                </Link>
            }

            {!isLink &&
                <span className={`u-category__link u-category__link--${slug}`}>
                    {title}
                </span>
            }
        </li>
    );
};

export default Category;

Category.propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    isLink: PropTypes.boolean,
};
