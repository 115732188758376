import React from 'react';
import PropTypes from 'prop-types';

import { graphql, Link } from 'gatsby';

import SEO from '../components/SEO';
import RecipeCard from '../components/recipe/RecipeCard';

import '../components/home/home.css';

const HomePage = ({ location, data }) => {
    const featuredRecipes = data.homepage.featuredRecipes;
    const latestRecipes = data.latest.nodes;
    const heroImage = data.homepage.homehero._rawAsset.url;
    const pagepathname = location.pathname;
    const desc = 'Fed up of scrolling forever to get the recipe for something, only to find out it’s measured in cups and ounces? Or that you’re going to need parchment paper to cook it on?!';

    return (
        <>
            <SEO
                title="Recipes without all the bullshit."
                pagepathname={pagepathname}
                image={heroImage}
                description={desc}
            />

            <div className="home__hero" style={{ backgroundImage: `url(${heroImage})` }}>
                <h1 className="u-title home__hero-title">
                    Recipes without all the bullshit.
                </h1>
            </div>

            <p className="content__lead">
                {desc}
            </p>

            <p className="content__intro">
                Fear not, friends! Here you’ll find recipes without bullshit. UK measurements, no adverts, no 10,000 word essay telling you how good a certain waffle maker is before we even get to the ingredients list, no ‘click to scroll to recipe’ only to find that takes you to more bullshit and a ‘jump to content’ link. No 400 autoplaying YouTube video adverts, no bullshit ‘Please sign up to our newsletter’ modal windows covering everything up. Just the recipes, and only those I’ve personally cooked and tested, and with honest reviews and ratings on each.
            </p>

            <p className="content__intro">
                Well stop faffing about then, look through our <Link to="/recipes/" className="u-link">recipes</Link>!
            </p>

            <div className="home__featured">
                <h2 className="u-tertiary-title home__featured-title">
                    Newest recipes
                </h2>

                <div className="recipes">
                    {latestRecipes.map(recipe => (
                        <RecipeCard
                            key={`latest-${recipe.id}`}
                            title={recipe.title}
                            id={recipe.id}
                            image={recipe.image}
                            slug={recipe.slug}
                            basicinfo={recipe.basicinfo}
                            categories={recipe.categories}
                            ratings={recipe.ratings}
                            cuisine={recipe.cuisine}
                            recipetype={recipe.recipetype}
                            author={recipe.author}
                        />
                    ))}
                </div>
            </div>

            <div className="home__featured">
                <h2 className="u-tertiary-title home__featured-title">
                    Our all-time favourites
                </h2>

                <div className="recipes">
                    {featuredRecipes.map(recipe => (
                        <RecipeCard
                            key={`featured-${recipe.id}`}
                            title={recipe.title}
                            id={recipe.id}
                            image={recipe.image}
                            slug={recipe.slug}
                            basicinfo={recipe.basicinfo}
                            categories={recipe.categories}
                            ratings={recipe.ratings}
                            cuisine={recipe.cuisine}
                            recipetype={recipe.recipetype}
                            author={recipe.author}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export const query = graphql`
    query HomepageQuery {
        latest: allSanityRecipe(
            sort: {order: DESC, fields: _createdAt}
            , limit: 3
        ) {
            nodes {
                id
                image {
                    _rawAsset(resolveReferences: {maxDepth: 10})
                }
                slug {
                    current
                }
                basicinfo {
                    cleantime
                    preptime
                    cooktime
                    serves
                }
                title
                slug {
                    current
                }
                categories {
                    id
                    title
                    slug {
                        current
                    }
                }
                ratings {
                    taste
                    ease
                    cost
                    portion
                }
                cuisine
                recipetype
                author
                tags {
                    value
                    label
                }
            }
        }
        homepage: sanityHomepage {
            homehero {
                _rawAsset(resolveReferences: {maxDepth: 10})
            }
            featuredRecipes {
                id
                image {
                    _rawAsset(resolveReferences: {maxDepth: 10})
                }
                slug {
                    current
                }
                basicinfo {
                    cleantime
                    preptime
                    cooktime
                    serves
                }
                title
                slug {
                    current
                }
                categories {
                    id
                    title
                    slug {
                        current
                    }
                }
                ratings {
                    taste
                    ease
                    cost
                    portion
                }
                cuisine
                recipetype
                author
                tags {
                    value
                    label
                }
            }
        }
    }
`;

export default HomePage;

HomePage.propTypes = {
    location: PropTypes.object,
    data: PropTypes.object,
};
