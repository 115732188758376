import React from 'react';
import PropTypes from 'prop-types';

const Rating = ({ styleName, ratings, output, isOnlyRatingOnPage = false }) => {
    const { taste, ease, cost, portion } = ratings;

    switch (output) {
        case 'taste': {
            return (
                <div className={`u-rating u-rating--${styleName} u-rating--${taste * 10}`}>
                    This recipe is rated {taste} out of 5 for tastiness, five being the tastiest.
                </div>
            );
        }

        case 'ease': {
            return (
                <div className={`u-rating u-rating--${styleName} u-rating--${ease * 10}`}>
                    This recipe is rated {ease} out of 5 for recipe difficult, five being the most difficult to make.
                </div>
            );
        }

        case 'cost': {
            return (
                <div className={`u-rating u-rating--${styleName} u-rating--${cost * 10}`}>
                    This recipe is rated {cost} out of 5 for how expensive it is to make, five being the most expensive.
                </div>
            );
        }

        case 'portion': {
            return (
                <div className={`u-rating u-rating--${styleName} u-rating--${portion * 10}`}>
                    This recipe is rated {portion} out of 5 for portion size, five being the largest size.
                </div>
            );
        }

        case 'overall':
        default: {
            const overallRating = Math.round(((taste + ease + cost + portion) / 4) * 2) / 2;

            return (
                <div className={`u-rating u-rating--${styleName} u-rating--${overallRating * 10}`}>
                    This recipe is rated
                    {isOnlyRatingOnPage && <span itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
                        <span itemProp="ratingValue">{overallRating}</span>
                        <span itemProp="ratingCount" hidden>1</span>
                    </span>}
                    {!isOnlyRatingOnPage && overallRating}
                     stars.
                </div>
            );
        }
    }
};

export default Rating;

Rating.propTypes = {
    props: PropTypes.object,
};
